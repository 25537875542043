import React from 'react';
import 'react-tabulator/css/tabulator.min.css';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import SubmitButtonWithSpinner from '../Components/Common/SpinnerSubmitButton';
import { TypeOptions, toast } from 'react-toastify';
import { axios } from '../DataLoader';
import { HttpStatusCode } from 'axios';
import { SapEmployeeTypeaheadFormControl } from '../Components/Common/Typeaheads/SAPEmployeeTypeahead2';
import { useParams } from 'react-router-dom';
import * as z from 'zod';
import { UserToken } from '../Types/types';

const HELP_LINK = '';

export const ReceiveSparePartsDataValidationSchema = z.object({
    Requester: z.number().int(),
    sapDatabase: z.string(),
    DocNum: z.number().int(),
    DocCode: z.string(),
    TransportToken: z.string(),
    Comments: z.string().max(200).optional()
});

export type ReceiveSparePartsData = z.infer<typeof ReceiveSparePartsDataValidationSchema>;

type SparePartsReceiveFormParams = {
    src: string;
    DocNum: string;
};

const parseIntSafe = (value: string | undefined, fallback?: number | ((value: string | undefined) => number | undefined)): number | undefined => {
    // fallback function
    const doFallback = (value: string | undefined) => {
        if (!fallback) throw new Error('Invalid number');
        return typeof fallback === 'function' ? fallback(value) : fallback;
    };

    if (value == null) return doFallback(value);

    const result = parseInt(value);
    if (isNaN(result)) return doFallback(value);

    return result;
};

export default function SparePartsReceiveForm({ user }: { user: UserToken }): JSX.Element {
    const { src, DocNum } = useParams<SparePartsReceiveFormParams>();
    const toastId = React.useRef<any>(null);
    const DocNumInt = parseIntSafe(DocNum, () => undefined);

    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors, isSubmitting, isLoading, isValid }
    } = useForm<ReceiveSparePartsData>({
        resolver: zodResolver(ReceiveSparePartsDataValidationSchema),
        mode: 'all',
        defaultValues: {
            Requester: -1,
            sapDatabase: src,
            DocNum: DocNumInt
        }
    });

    async function onSubmit(data: ReceiveSparePartsData): Promise<void> {
        try {
            toastId.current = toast.loading('Submitting your request, please wait...', {
                isLoading: true,
                type: 'info',
                closeButton: true
            });

            const result = await axios({
                method: 'POST',
                url: '/process-automation/receiveSpareParts',
                data
            });

            let message = '';
            let toastType: TypeOptions = 'success';
            const { document, emailSent } = result.data;
            switch (result.status) {
                case HttpStatusCode.Created:
                    message = `Inventory transfer was created. DocNum: ${document.DocNum}. \n`;
                    if (emailSent === true) message += `Email notification was sent.`;
                    toastType = 'success';
                    break;
                case HttpStatusCode.NoContent:
                    message = `Inventory transfer is now waiting approval.`;
                    toastType = 'warning' as const;
                    break;
            }

            toast.update(toastId.current, {
                render: message,
                type: toastType,
                autoClose: 10000,
                isLoading: false
            });

            reset();
        } catch (error) {
            toast.update(toastId.current, {
                render: `${error}\n${(error as any)?.response?.message}`,
                type: 'error',
                autoClose: 10000,
                isLoading: false
            });
        }
    }

    function FormControlEx({ label, name, children }: { label: string; name: keyof ReceiveSparePartsData; children: React.ReactNode }): JSX.Element {
        return (
            <Form.Group as={Row}>
                <Form.Label column className={'pb-xxs-0 pb-lg-2'}>
                    {label}
                </Form.Label>
                <Col sm={10}>{children}</Col>
                <Form.Control.Feedback type="invalid">{errors[name]?.message}</Form.Control.Feedback>
            </Form.Group>
        );
    }

    return (
        <Container fluid="xs" className={'gx-0 container-md'} style={{ maxWidth: '800px' }}>
            <h3>
                {'Confirm spare part receive '}
                <a href={HELP_LINK} target="_blank" rel="noreferrer">
                    <i className="bi bi-question-circle"></i>
                </a>
            </h3>
            <p>Please verify the content of the package with items on the Document Note, Confirm the Delivery Note Number (DocNum) and hit Submit</p>
            <p>Submitted form with create a request for warehouse to count-in these spare parts to your inventory on site</p>
            <p style={{ color: 'red' }}>If the content of the package does not match, or maybe the content is partialy damaged, contact your administrator</p>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <FormControlEx label="Receiver" name="Requester">
                    <SapEmployeeTypeaheadFormControl
                        control={control}
                        disabled
                        name="Requester"
                        src={'live'}
                        size="sm"
                        returnField={'SalesPersonCode'}
                        placeholder={`${user?.name ?? ''} - ${user?.email ?? ''}`}
                    />
                </FormControlEx>

                <FormControlEx label="Doc Num" name="DocNum">
                    <Form.Control size="sm" as="input" isInvalid={Boolean(errors.DocNum)} disabled={true} {...register('DocNum')} />
                </FormControlEx>

                <FormControlEx label="Verification Code" name="DocCode">
                    <Form.Control size="sm" as="input" isInvalid={Boolean(errors.DocCode)} disabled={isSubmitting || isLoading} {...register('DocCode')} />
                </FormControlEx>

                <FormControlEx label="Comments" name="Comments">
                    <Form.Control
                        size="sm"
                        as="textarea"
                        maxLength={200}
                        rows={2}
                        placeholder="Add a comment"
                        isInvalid={Boolean(errors.Comments)}
                        disabled={isSubmitting || isLoading}
                        {...register('Comments')}
                    />
                </FormControlEx>

                <hr className="hr" />
                <Row>
                    <Col>
                        <p>
                            Need help?{' '}
                            <a href={HELP_LINK} target="_blank" rel="noreferrer">
                                Click here
                            </a>{' '}
                            for manual or ask in{' '}
                            <a href="https://chat.google.com/room/AAAANpZpog0?cls=7" target="_blank" rel="noreferrer">
                                google space
                            </a>
                        </p>
                        <p>Do not use this if you are not exchanging a piece to piece!</p>
                    </Col>
                    <Col className="d-flex flex-column align-items-end">
                        <SubmitButtonWithSpinner isSubmitting={isSubmitting} disabled={isSubmitting || isLoading || !isValid} />
                    </Col>
                </Row>
                <Row>
                    <br />
                    <br />
                    <br />
                    <br />
                </Row>
            </Form>
        </Container>
    );
}
